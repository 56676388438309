<template lang='pug'>
  .loading-wrapper
    .collection(v-if="page === 'collection'")
      .collection-product(
        v-for="i in 20"
        :key="i"
      )
        vue-image.product-image(
          :height='500'
          :width='500'
        )
        h4.title #[br]
        p.price #[br]
    .product(v-if="page === 'product'")
      .left
        vue-image.product-image(
          :height='500'
          :width='500'
        )
      .right
        h1.capitalize {{ title.replace(/-/g, ' ') }}
    .error-message(v-else-if="error")
      h2 Uh oh.. something went wrong. Try reloading the page, or:
      button(@click="$router.push('/products')") View Products
    .spinner-wrapper(v-else)
      .spinner
</template>

<script>
export default {
  props: {
    page: {
      type: String,
      default: 'collection'
    },
    title: {
      type: String,
      default: '&nbsp;'
    }
  },
  data () {
    return {
      error: false
    }
  },

  mounted () {
    setTimeout(() => {
      this.error = true
    }, 5000)
  }
}
</script>

<style lang="scss" scoped>
.spinner-wrapper {
  margin: 0 auto;
  padding: 0 1.25em 2em;
  text-align: center;

  .spinner {
    width: 36px;
    height: 36px;
    margin: 30px auto;
    transform: rotate(0deg);
    animation: spinner .6s infinite linear;
    border: 4px solid $white;
    border-top: 4px solid $black;
    border-radius: 50%;
    stroke: $white;

    @media(max-width: $bp-s) {
      width: 24px;
      height: 24px;
      border: 2px solid $white;
      border-top: 2px solid $black;
    }
  }
}

.collection-product {
  .title, .price {
    margin: 0;
  }

  .title {
    margin-top: 0.5rem;
  }

  .product-image, /deep/ .vue-image {
    width: 100%;
  }
}
</style>
